import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
import Jobs from "../components/Jobs"
 const About = ({data:{
                    about:{ nodes},
                      },
                    } ) => {

          const {title,stack,info,image} = nodes[0]

 return (<Layout>
   <section className="about-page">

     <div className="section-center about-center">
       
       <Image fluid={image.childImageSharp.fluid} className="about-img"/>
       
       <article className="about-text">
       
         <Title title={title}/>
       
         <p> {info} </p>
       
         <div className="about-stack">
            {stack.map(item => {
              return <span key={item.id}>{item.title}</span>
            })}
       
         </div>
       
       </article>
    
     </div>
   
   </section>
   <Jobs/>          
 </Layout>)
 }
 

export const query = graphql`
  {
    about:allStrapiAbout {
      nodes {
        stack {
          id
          title
        }
        title
        info
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
 

 

export default About
  